const ticketTypes = [
  {
    text: "four",
    switcherText: "4",
    count: 4,
    price: 12000
  },
  {
    text: "eight",
    switcherText: "8",
    count: 8,
    price: 18000
  },
  {
    text: "tvelve",
    switcherText: "12",
    count: 12,
    price: 25000
  },
  {
    text: "tventyfour",
    switcherText: "24",
    count: 24,
    price: 45000
  }
];

export default ticketTypes;